<template>
  <el-main>
    <el-button type="primary" size="mini" style="margin-bottom: 15px" @click="toAddPackage">+ 添加套餐</el-button>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="日期范围：">
        <el-date-picker
          v-model="searchForm.time"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          size="mini"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="店铺名：">
        <el-input v-model="searchForm.store_name" size="mini"></el-input>
      </el-form-item>
      <el-form-item label="套餐名：">
        <el-input v-model="searchForm.goods_name" size="mini"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getDataList(1)">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column align="center">
        <template #header>
          <!-- <el-checkbox></el-checkbox> -->
          编号
        </template>
        <template v-slot="{ row }">
          {{ row.id }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="绑定商品" align="center" width="250px">
        <template v-slot="{ row }">
          <div class="goodsBox">
            <el-image :src="row.goods_info.goods_picture"></el-image>
            <div class="goodsName">{{ row.goods_info.goods_name }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="salePrice" label="售价" align="center">
        <template v-slot="{ row }">
          <div>{{ row.goods_info.goods_price }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="people_min" label="可开人数" align="center"></el-table-column>
      <el-table-column prop="people_max" label="最多人数" align="center"></el-table-column>
      <el-table-column label="支持合伙人免单" align="center">
        <template v-slot="{ row }">
          <el-switch disabled v-model="row.main_free" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="均摊模式" align="center">
        <template v-slot="{ row }">{{ row.deposit == 1 ? '全额模式' : '定金模式' }}</template>
      </el-table-column>
      <el-table-column label="定金金额" align="center">
        <template v-slot="{ row }">{{ row.deposit == 2 ? row.deposit_money : '--' }}</template>
      </el-table-column>
      <el-table-column prop="name" label="可用时间" align="center">
        <template v-slot="{ row }">
          <div>{{ row.allow_times[0] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="是否启用" align="center">
        <template v-slot="{ row }">
          <el-switch @change="changeStatus(row)" v-model="row.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="创建时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column prop="name" label="所属店铺" align="center" width="250px">
        <template v-slot="{ row }">
          <div class="goodsBox">
            <el-image :src="row.store_info.store_logo"></el-image>
            <div class="goodsName">{{ row.store_info.store_name }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button type="primary" size="small" @click="editPackage(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="searchForm.total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';

export default {
  components: {
    Paging,
  },
  data() {
    return {
      getDateformat,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      searchForm: {
        time: [],
        store_name: '',
        goods_name: '',
        total: 0,
        page: 1,
        rows: 10,
      },
      dataList: [],
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    editPackage(data) {
      this.$router.push('/extension/packageManagement/addPackage?id=' + data.id);
      console.log(data);
    },
    clearSearch() {
      this.searchForm = { time: [], store_name: '', goods_name: '', total: 0, page: 1, rows: 10 };
      this.getDataList();
    },
    toAddPackage() {
      this.$router.push('/extension/packageManagement/addPackage');
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      // 重新获取分页数据
      this.getDataList();
    },
    getDataList(style) {
      if (style) {
        this.searchForm.page = 1;
        this.dataList = [];
      }
      let obj = {
        page: this.searchForm.page,
        rows: this.searchForm.rows,
      };
      if (this.searchForm.time?.length) {
        obj.start_time = Math.ceil(this.searchForm.time[0].getTime() / 1000);
        obj.end_time = Math.ceil(this.searchForm.time[1].getTime() / 1000);
        if (obj.start_time == obj.end_time) {
          obj.end_time += 60 * 60 * 24 - 1;
        }
      }
      if (this.searchForm.store_name) obj.store_name = this.searchForm.store_name;
      if (this.searchForm.goods_name) obj.goods_name = this.searchForm.goods_name;
      this.$axios.post(this.$api.yiyun.goodsList, obj).then(res => {
        if (res.code == 0) {
          res.result.list.map(item => {
            item.goods_info.goods_picture = item.goods_info.goods_picture.split('|')[0];
          });
          this.dataList = res.result.list;
          this.searchForm.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeStatus(data) {
      let is_open = data.is_open ? 0 : 1;
      let str = is_open ? '此操作将禁用该套餐' : '此操作将启用该套餐';
      this.$confirm(`${str}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.yiyun.changeStatus, { id: data.id, is_open: is_open ? 0 : 1 });
        })
        .then(res => {
          if (res.code === 0) {
            let msg = is_open ? '成功禁用' : '成功启用';
            this.$message.success(msg);
          } else {
            this.$message.error(res.msg);
            data.is_open = data.is_open ? 0 : 1;
          }
        })
        .catch(() => {
          data.is_open = data.is_open ? 0 : 1;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .goodsBox {
    display: flex;
    align-items: center;
    .el-image {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      margin-right: 10px;
    }
    .goodsName {
      max-width: 150px;
    }
  }
}
</style>
